/* loginform.css */

html,
body {
  height: 100%;
  margin: 0;
}

.login-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Adjust to fit image and form */
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 1800px;
  margin-left: 400px;
  margin-right: 0px;
  margin: 0 auto; /* Center the container */
  margin-top: 0;
  background-color: #f0f2f5; /* Optional: Add a background color for better contrast */
}

.login-image {
  display: flex;
  justify-content: left;
  align-items: center;
}

.login-image img {
  /* max-width: 100%; */
  height: 1000px;
  width: 1000px;
  border-radius: 8px; /* Optional: Add border radius to match the form */
}

.login-form {
  width: 100%;
  max-width: 800px;
  height: 100%;
  padding: 2rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
}

.login-form h2 {
  text-align: center;
  font-style: bold;
  font-size: xx-large;
  margin-top: 300px;
  margin-bottom: 1rem;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
}

.form-group input {
  width: 100%;
  height: 50px;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.error-message {
  color: red;
  font-size: 0.875rem;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login-button {
  background-color: #007bff;
  color: white;
  height: 50px;
  width: 900px;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.reset-password {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}

.login-error {
  color: red;
  margin-top: 1rem;
  text-align: center;
}
